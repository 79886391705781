import router from '@/router'
import {compose, equals, prop} from 'ramda'
import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {
  AttributeType,
  Class,
  ClassShallowProps, DataAction, DataActionType,
  OWL,
  RDFS,
  ShallowClass, UserInfo
} from "~/shared";
import {get as getRoot} from "@/api/root";
import {getClass, getClasses} from "@/api/classes";
import {getLang} from "@/utils/lang";
import {Endpoint} from "@/types";
import {equalsById} from "@/functions";
import SelectedClassStorage from "@/core/selected-class-storage";
import {getMany as getAttributes} from "@/api/attributes";
import {getMany as getReferences} from "@/api/references";
import {openSocket} from "@/socket";
import {get} from "@/api/individuals";


let lang
let endpoint: Endpoint
let selectedClassStorage

let webSocket
let webSocketCache

function closeSocket() {
  if (!webSocket) return
  webSocket.close()
}

async function initSocket(context) {
  const endpoint = context.rootState.endpoints.endpoint
  const currentClass = context.state.current
  webSocket = await openSocket(endpoint.id, lang.id, currentClass.id)
  webSocket.subscribe('action', ({action, user}) => {
    const versions = context.rootState.ontologyVersions
    let isLastVersion = true
    if (versions.access) {
      isLastVersion = versions.viewVersion === null || versions.viewVersion === versions.lastVersion.uri
    }
    if (isLastVersion) {
      console.info('received action', {action, user})
      if (action.type === OWL.DatatypeProperty || action.type === OWL.ObjectProperty) {
        if (action.action === 'set') {
          handlePropertySet(context, action as DataAction<'set', AttributeType>, user)
        }
        else if (action.action === 'remove') {
          handlePropertyRemove(context, action as DataAction<'remove', AttributeType>, user)
        }
      }
      if (action.type === OWL.NamedIndividual) {
        this.dispatch('namedIndividualAction', {
          action: action as DataAction<DataActionType, OWL.NamedIndividual>,
          user,
        })
      }
    }
  })
}

let handleAttributeTimeout = null
let handleAttributeList: string[] = []
let handleReferenceTimeout = null
let handleReferenceList: string[] = []

function handlePropertySet(context, action: DataAction<'set', AttributeType>, user?: UserInfo) {
  const endpoint = context.rootState.endpoints.endpoint
  const lang = context.rootState.lang.currentLang
  if (action.type === OWL.DatatypeProperty) {
    handleAttributeList.push(...action.objects.map((e) => e.id))
    if (handleAttributeTimeout) {
      clearTimeout(handleAttributeTimeout)
    }
    handleAttributeTimeout = setTimeout(async () => {
      const ids = handleAttributeList
      handleAttributeList = []
      const {data} = await getAttributes(endpoint.id, ids, lang.id)
      for (const item of data) {
        if (context.rootState.literals.list.find(equalsById(item))) {
          context.commit('literals/patch', item, {root: true})
        }
        else {
          context.commit('literals/add', item, {root: true})
        }
      }
    }, 1000)
  }

  if (action.type === OWL.ObjectProperty) {
    handleReferenceList.push(...action.objects.map((e) => e.id))
    if (handleReferenceTimeout) {
      clearTimeout(handleReferenceTimeout)
    }
    handleReferenceTimeout = setTimeout(async () => {
      const ids = handleReferenceList
      handleReferenceList = []
      const {data} = await getReferences(endpoint.id, ids, lang.id)
      for (const item of data) {
        if (context.rootState.references.list.find(equalsById(item))) {
          context.commit('references/patch', item, {root: true})
        }
        else {
          context.commit('references/add', item, {root: true})
        }
      }
    }, 1000)
  }
}

function handlePropertyRemove(context, action: DataAction<'remove', AttributeType>, user?: UserInfo) {
  if (action.type === OWL.DatatypeProperty) {
    action.objects.map(({id}) => {
      context.commit('literals/remove', id, {root: true})
    })
  }
  if (action.type === OWL.ObjectProperty) {
    action.objects.map(({id}) => {
      context.commit('references/remove', id, {root: true})
    })
  }
}

class State {
  pending: boolean = false

  root: ShallowClass = null
  classes: ShallowClass[] = []

  current: Class = null

  isModelStorage: boolean = false
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  clear(state) {
    state.root = null
    state.current = null
    state.classes = []
  },

  setPending(state, payload) {
    state.pending = payload
  },

  setRoot(state, payload) { state.root = payload},
  setClasses(state, payload) {state.classes = payload},

  addClass(state, payload: ShallowClass) {
    const indexOfClass = state.classes.findIndex(equalsById(payload))
    if (!payload.props) return
    if (indexOfClass > -1) {
      this.commit('classes/patchClass', payload)
    }
    else {
      state.classes.push(payload)
    }
  },
  patchClass(state, payload: ShallowClass) {
    const actualClass = state.classes.find(({id}) => id === payload.id)
    if (actualClass) {
      if (payload.access) {
        actualClass.access = payload.access
      }
      if (payload.operations) {
        actualClass.operations = payload.operations
      }
      if (payload.props) {
        if (actualClass.props === undefined || actualClass.props === null)
          actualClass.props = {} as ClassShallowProps
        actualClass.props = Object.assign(actualClass.props, payload.props)
        if (actualClass.name !== payload.name) {
          actualClass.name = payload.name
          state.classes.forEach((item) => {
            item.props[RDFS.subClassOf].forEach((parent) => {
              if (parent.id === payload.id) {
                parent.name = payload.name
              }
            })
          })
        }
      }
    }
  },
  removeClass(state, id: string) {
    const indexOfClass = state.classes.findIndex((item) => item.id === id)
    if (indexOfClass > -1) {
      state.classes.splice(indexOfClass, 1)
    }
    state.classes.forEach((item) => {
      const index = item.props[RDFS.subClassOf].findIndex(compose(equals(id), prop(id)))
      if (index > -1) {
        item.props[RDFS.subClassOf].splice(index, 1)
      }
    })
  },

  setCurrentClass(state, payload) {
    state.current = payload
    selectedClassStorage.set(payload.id)
  },

  setIsModelStorage(state, payload) {
    state.isModelStorage = payload
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    lang = getLang()
    endpoint = context.rootState.endpoints.endpoint
    const user = context.rootState.auth.user
    selectedClassStorage = new SelectedClassStorage(endpoint.id, user.login)
    context.commit('clear')

    context.commit('setRoot', (await getRoot(endpoint.id, lang)).data)
    context.commit('setClasses', (await getClasses(endpoint.id, lang, context.rootState.ontologyVersions.viewVersion)).data)
    await context.dispatch('initClass')
  },
  async initClass(context) {
    let classId = router.history.current.params.class

    if (!classId) {
      classId = selectedClassStorage.get()
      if (!classId) return
    }

    if (classId) {
      const _class = context.state.classes.find((el) => el.id === classId)
      if (!_class) return

      await context.dispatch('loadClass', classId)
      router.push({
        name: 'editorMainClassIndividuals',
        params: {
          class: classId,
          endpoint: endpoint.id,
        },
        query: {
          'object-id': classId,
          'object-type': OWL.Class,
        },
      })
    }
  },
  async loadClass(context, payload) {
    context.commit('setPending', true)
    try {
      closeSocket()
      const lang = getLang()
      const endpoint = context.rootState.endpoints.endpoint
      const viewVersion = context.rootState.ontologyVersions.viewVersion
      const cls = await getClass(endpoint.id, payload, lang, viewVersion)


      //Запрос для определения, являются объекты класса частью модели или не являются
      const {data} = await get(endpoint.id, payload, null, OWL.Class, context.rootState.ontologyVersions.isOn ?? false)
      context.commit('setIsModelStorage', data.meta.isModelStorage)

      context.commit('setCurrentClass', cls.data)

      context.dispatch('literals/load', null, {root: true})
      context.dispatch('references/load', null, {root: true})
      context.dispatch('individuals/init', null, {root: true})

      initSocket(context)
    } catch (e) {

    }
    context.commit('setPending', false)
  },

  async select(context, payload) {
    const route = router.history.current
    const params = {
      class: payload,
      endpoint: endpoint.id,
    }
    const query = {
      'object-type': OWL.Class,
      'object-id': payload,
    }
    const handleError = (err) => {
      // Ignore the vuex err regarding  navigating to the page they are already on.
      if (err.name !== 'NavigationDuplicated' &&
        !err.message.includes('Avoided redundant navigation to current location')) {
        // But print any other errors to the console
        console.error(err)
      }
    }

    await context.dispatch('loadClass', payload)

    if (route.name === 'editorMainClassLiterals') {
      router
        .push({
          name: 'editorMainClassLiterals',
          params,
          query,
        })
        .catch(handleError)
      return
    }
    if (route.name === 'editorMainClassReferences') {
      router
        .push({
          name: 'editorMainClassReferences',
          params,
          query,
        })
        .catch(handleError)
      return
    }
    router
      .push({
        name: 'editorMainClassIndividuals',
        params,
        query,
      })
      .catch(handleError)
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
